import { useAtom } from 'jotai';
import React, { useState, useEffect } from 'react';
import { allTableAtom } from '../atoms/allTableAtom';
import { tableAtom } from "../atoms/tableAtom";
import { allTurbAtom } from "../atoms/allTurbAtom";
import { turbAtom } from "../atoms/turbAtom";
import { siteFilterAtom } from "../atoms/siteFilterAtom";
import { activeTableSitesAtom } from '../atoms/activeTableSites';
// import { draggableAtom } from '../atoms/draggableAtom';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import Select from 'react-select';

export default function SummaryDropdown(){
  //Table
  const [ allTableData, ] = useAtom(allTableAtom); 
  const [ , setTableData ] = useAtom(tableAtom);
  //Turb
  const [ allTurbData, ] = useAtom(allTurbAtom);
  const [ , setTurbData ] = useAtom(turbAtom);
  //Site filter
  const [ siteFilter, setSiteFilter ] = useAtom(siteFilterAtom); 
  //Dropdown states
  const [ selectedCriteria, setSelectedCriteria ] = useState('Customer');
  const [ switchState, setSwitchState ] = useState(true);
  // const updateTurb = useUpdateTurb();
  const [activeTableSites, setActiveTableSites] = useAtom(activeTableSitesAtom); 
  //Draggable Layout
  // const [dragState, setDragState] = useAtom(draggableAtom);

  // Handle switch toggle
  useEffect(() => {
    const newActiveSites = switchState 
      ? allTableData.filter(site => site.Active === 1) 
      : allTableData;
    setActiveTableSites(newActiveSites);
  }, [switchState, allTableData, setActiveTableSites]);

  // Update tableData when activeTableSites changes
  useEffect(() => {
    setTableData(activeTableSites);
  }, [activeTableSites, setTableData]);

  const handleSwitchToggle = () => {
    setSwitchState(prevState => !prevState);
  };
  // const toggleDraggable = () => setDragState(!dragState);


  // map data based on the selected criteria
  const getOptions = (criteria) => {
    if (!activeTableSites) return [];

    let options = [];

    if (criteria === 'Customer') {
      // Get unique customer names, sort them, and map to options
      const uniqueCustomers = new Set(activeTableSites.map(item => item.Customer));
      options = Array.from(uniqueCustomers).sort().map(customer => ({
        label: customer,
        value: customer
      }));
    } else {
      // Prepare options for other criteria
      options = activeTableSites.map((item) => ({
        label: item[criteria] ? item[criteria].toString() : '',
        value: item[criteria]
      })).filter(option => option.label);

      if (criteria === 'SiteID') {
        // Sort alphabetically for 'SiteID'
        options.sort((a, b) => a.label.localeCompare(b.label));
      } else if (criteria === 'SiteUID') {
        // Sort alphanumerically for 'SiteUID'
        options.sort((a, b) => a.label.localeCompare(b.label));
      }
    }

    return options;
  };




  const handleCriteriaChange = (criteria) => {
      setSelectedCriteria(criteria);
  };

    const selectSites = (selectedOptions) => {

  if (selectedOptions && selectedOptions.length > 0) {
      // When specific sites are selected tableData is updated to those sites
      console.log('activeTableSites:', activeTableSites);
      const selectedValues = selectedOptions.map(option => option.value);
      const filteredTableData = activeTableSites.filter(item => selectedValues.includes(item[selectedCriteria]));

      setTableData(filteredTableData)

      // Filter the turbData based on the selected sites
      const selectedSiteUIDs = new Set(filteredTableData.map(site => site.SiteUID));
      const siteFilterArray = Array.from(selectedSiteUIDs); // Convert Set to Array
      setSiteFilter(siteFilterArray);

      const filteredTurbData = allTurbData.filter(item => siteFilter.includes(item.SiteUID));
      setTurbData(filteredTurbData);
  } else {
      // When no specific sites are selected, use all data
      setSiteFilter('');
      setTableData(activeTableSites);
      setTurbData(allTurbData);
    }
  };

  return(
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <UncontrolledDropdown className="">
          <DropdownToggle caret >
            Select
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>
              Search by:
            </DropdownItem>
            <DropdownItem onClick={() => handleCriteriaChange('SiteID')}>SiteID</DropdownItem>
            <DropdownItem onClick={() => handleCriteriaChange('Customer')}>Customer</DropdownItem>
            <DropdownItem onClick={() => handleCriteriaChange('SiteUID')}>UID</DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              <FormGroup switch inline>
              <Input
                type="switch"
                checked={switchState}
                onChange={handleSwitchToggle} 
              />
                <Label>
                  Active Only
                </Label>
              </FormGroup>
          </DropdownItem>
          {/* <DropdownItem divider />
          <DropdownItem>
            <FormGroup switch inline>
              {dragState ? (
                <Label>Layout Unlocked</Label>
              ) : (
                <Label>Layout Locked</Label>
              )}
              <Input
                type="switch"
                checked={dragState}
                onChange={toggleDraggable} 
              />
            </FormGroup>
          </DropdownItem> */}
          </DropdownMenu>
        </UncontrolledDropdown>
      <Select
          options={getOptions(selectedCriteria)}
          onChange={selectSites}
          isClearable={true}
          isSearchable={true}
          isMulti={true}
          placeholder={selectedCriteria}
          className="basic-multi-select cancelSelectorName dropdown-size"
          classNamePrefix="select"
      />
    </div>
  )
}