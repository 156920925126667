import { useFormContext } from 'react-hook-form';
import { Form, FormGroup, Input, Label } from 'reactstrap';

export default function HybridForm() {
  const { register } = useFormContext();

  return(
    <Form>
      <FormGroup>
        <div className="d-flex justify-content-between">
          <Label check className="padding-3px">Is turbine part of a wind-PV-diesel system?</Label>
          <div>
            <Input 
              name="windPVDieselSystem"
              type="radio"
              value="Y"
              {...register("windPVDieselSystem")}
            />
            <Label check>Y</Label>
            <Input 
              name="windPVDieselSystem"
              type="radio"
              value="N"
              {...register("windPVDieselSystem")}
            />
            <Label check>N</Label> 
          </div>
        </div>
      </FormGroup>
      <FormGroup className = "d-flex justify-content-between">
        <div>
          <Label check className="padding-3px">PV array?</Label>
          <div>
            <Input 
              name="pvArray"
              type="radio"
              value="Y"
              {...register("pvArray")}
            />
            <Label check>Y</Label>
            <Input 
              name="pvArray"
              type="radio"
              value="N"
              {...register("pvArray")}
            />
            <Label check>N</Label> 
          </div>
        </div>
        <Input 
            className = "input-width"
            placeholder = "PV power rating (kw)"
            {...register("pvPowerRating")}
          />
      </FormGroup>
      <FormGroup className="d-flex justify-content-between">
        <div>
          <Label check className="padding-3px">Diesel Gen-set?</Label>
          <div>
            <Input 
              name="dieselGenSet"
              type="radio"
              value="Y"
              {...register("dieselGenSet")}
            />
            <Label check>Y</Label>
            <Input 
              name="dieselGenSet"
              type="radio"
              value="N"
              {...register("dieselGenSet")}
            />
            <Label check>N</Label> 
          </div>
        </div>
        <Input 
            className = "input-width"
            placeholder = "Generator rating (kw)"
            {...register("generatorRating")}
          />
      </FormGroup>
    </Form>
  );
}
