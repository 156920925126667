import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet, Link, useLocation } from "react-router-dom";
import Sites from '../components/handlers/Sites-handler';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Button
} from 'reactstrap';
import SummaryDropdown from './SummaryDropdown';

const logo = '/BergeyLogox.png';

export default function NavBar() {
  const [navOpen, setNavOpen] = useState(false);
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
    
  const toggle = () => setNavOpen(!navOpen);
  const location = useLocation();

    const renderSearchBar = () => {
      if (location.pathname === "/") {
        return <SummaryDropdown />;
      } else if (location.pathname === "/turbine") {
        return <Sites />;
      }
      return null;
    };

  return (
    <div>
      <Navbar expand="md" className="navbar-light bg-light fixed-top">
        <NavbarBrand tag={Link} to="/">
          <img src={logo} alt="Logo" style={{ height: '30px' }}/>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={navOpen} navbar>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink tag={Link} to="/">Summary</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/turbine">Turbine</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#">Download</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <NavbarText className="ml-auto nav-space">
          {renderSearchBar()}
        </NavbarText>
        <NavbarText>
          {isAuthenticated ? (
            <Button onClick={() => logout({ returnTo: "/" })}>Logout</Button>
          ) : (
            <Button onClick={() => loginWithRedirect()}>Login</Button>
          )}
        </NavbarText>
      </Navbar>
      <Outlet/>
    </div>
  );
}
