import { useQuery } from "@tanstack/react-query";
import axios from 'axios';
import { useAtom } from 'jotai';
import { siteAtom } from "../../atoms/SiteAtom";
import { chartAtom } from "../../atoms/chartAtom"; // Import the chartAtom
import Chart from "../tables-charts/Chart";
import ChartMenu from "../tables-charts/ChartMenu";
import {
    Card,
    CardBody,
    CardFooter
  } from 'reactstrap';

export default function ChartHandler() {
  // Initiate global states
  const [site] = useAtom(siteAtom);
  const [chartData, setChartData] = useAtom(chartAtom);

  const defaultInterval = '12Months';
  const selectedInterval = chartData.Interval || defaultInterval;

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://www.windnow.us/api/chartData?interval=${selectedInterval}`);
      const fetchedData = response.data;
      console.log("Data fetched successfully:", fetchedData);
      try {
        updateChartAtom(fetchedData);
      } catch (error) {
        console.error("Error", error);
      }  
      return fetchedData;  // Return data for caching by React Query
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;  // Re-throw the error to be caught by React Query
    }
  };
  
  const { isLoading, isError, error } = useQuery({
    queryKey: ['chartData', site.SiteUID, selectedInterval],
    queryFn: fetchData,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  // Update chartAtom with the data
  function updateChartAtom(fetchedData){
    console.log("updateChartAtom");
    // Match siteAtom SiteUID with data
    const filteredData = fetchedData.filter((item) => item.SiteUID === site.SiteUID);
    // Map the fetched data to the format expected by the atom
    const mappedData = filteredData.map(item => ({
      ...item,
      Interval: selectedInterval,
    }));


    // Update the chartAtom with the filtered data
    setChartData((prevChartState) => ({
      ...prevChartState,
      chartData: mappedData, // Update the chartData property
    }));

    console.log("Updated ChartAtom with:", mappedData);
  };

  // Render loading or Error
  const renderLoadingOrError = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    } else if (isError) {
      return <div>Error: {error}</div>;
    }else{
    return `Energy Production Over the Last ${selectedInterval}`
    }
  };

  return (
    <>
    <Card className="chart-card d-flex display-6 text-center justify-content-center">
      <CardBody>
        <ChartMenu defaultInterval = '12Months'/>
        <Chart />
      </CardBody>
      <CardFooter>{renderLoadingOrError()}</CardFooter>
    </Card>
    </>
  );
}
