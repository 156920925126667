import { useFormContext } from 'react-hook-form';
import { FormGroup, Input } from 'reactstrap';
import AddressForm from "./AddressForm";

export default function CustomerForm() {
  const { register, formState: { errors } } = useFormContext();

  return (
    <>
      <FormGroup className="d-flex justify-content-around">
        <Input
          placeholder="First Name*"
          className={errors.firstName ? 'input-error' : ''}
          {...register("firstName", { required: true })}
        />
        <Input
          placeholder="Last Name*"
          className={errors.lastName ? 'input-error' : ''}
          {...register("lastName", { required: true })}
        />
      </FormGroup>

      <AddressForm />

      <FormGroup className="d-flex justify-content-around">
        <Input
          placeholder="Phone*"
          className={errors.phone ? 'input-error' : ''}
          {...register("phone", {
            required: true,
            pattern: {
              value: /^[0-9]+$/
            }
          })}
        />
        {errors.phone && <span>{errors.phone.message}</span>}

        <Input
          placeholder="Email*"
          className={errors.email ? 'input-error' : ''}
          {...register("email", {
            required: true,
            pattern: {
              value: /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/
            }
          })}
        />
      </FormGroup>
    </>
  );
}
