// Visuals and react imports
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import './App.css';
import React from "react";

// Page routing
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import AllTurbines from './pages/AllTurbines';
import RegForm from './pages/RegForm';
import ProtectedRoute from './components/ProtectedRoute';
import packageJson from '../package.json';

// App-based components
import NavBar from './navigation/Navbar';

const WithNavbarLayout = ({ children }) => (
  <>
    <NavBar/>
    <div>{children}</div>
    <p className='d-flex text-center justify-content-center'>v{packageJson.version} Intergrid LLC©2024</p>
  </>
)

function App() {
    return (
      <>
          <Routes>
            {/* Routes sans Navbar */}
            <Route path = "/registration" element = { <RegForm/> } />

            {/* Routes with Navbar */}
            <Route element = {<WithNavbarLayout />}>
              <Route path = "/" element = {
                <ProtectedRoute>
                  <AllTurbines/>
                </ProtectedRoute> } />
              <Route path = "/turbine" element = { 
                <ProtectedRoute>
                  <Home/>
                </ProtectedRoute> } />
            </Route>
          </Routes>
      </>
    )
}

export default App;
