import { useFormContext } from 'react-hook-form';
import { FormGroup, Input, Form } from 'reactstrap';
import AddressForm from "./AddressForm";

export default function DealerForm() {
  const { register, formState: { errors } } = useFormContext();

  return(
    <Form>
      <FormGroup>
        <Input 
          placeholder="Dealer Name"
          {...register("dealerName", { required: "Dealer Name is required" })}
        />
        {errors.dealerName && <span>{errors.dealerName.message}</span>}
      </FormGroup>
      <AddressForm/>
      <FormGroup>
        <Input 
          placeholder="Phone"
          {...register("dealerPhone", {
            required: "Phone is required",
            pattern: {
              value: /^[0-9]+$/,
              message: "Phone number must be numeric"
            }
          })}
        />
        {errors.dealerPhone && <span>{errors.dealerPhone.message}</span>}
      </FormGroup>
    </Form>
  );
}
