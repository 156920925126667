// Query-based immports
import { useQuery } from "@tanstack/react-query";
import axios from 'axios';

// State imports
import { useAtom } from 'jotai';
import { siteAtom } from "../../atoms/SiteAtom";
import { draggableAtom } from "../../atoms/draggableAtom";
import React, { useState } from 'react';

// Visuals import
import Select from 'react-select';
import {
    UncontrolledDropdown,
    DropdownToggle, 
    DropdownMenu,
    DropdownItem,
    FormGroup,
    Input,
    Label
  } from 'reactstrap';

  const customStyles = {
    // Styles for the group titles
    groupHeading: (provided, state) => ({
      ...provided,
      color: 'black',
      borderTop: '1px solid lightgrey',
      height: '100%',
      textTransform: 'none',
      fontWeight: 'normal',
      fontSize: '1rem',
    }),
      // Styles for the group containers
    group: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid lightgrey',
  }),

    // Styles for the options within the groups
    option: (provided, state) => {
      if (state.data.isInGroup) {
        return {
          ...provided,
          // Custom styles for options in groups
          backgroundColor: state.isFocused ? "lightblue" : "white",
          color: "gray",
          paddingLeft: "30px",
        };
      }
      // Styles for non-grouped options
      return {
        ...provided,
        backgroundColor: state.isFocused ? "lightgray" : "white",
        color: "black",
      };
    },
  
  };
  

export default function Sites() {
  // Initiate global state
  const [, setSite] = useAtom(siteAtom);
  const [ selectedCriteria, setSelectedCriteria ] = useState('Customer');
  const [ switchState, setSwitchState ] = useState(true);
  const [ allSites, setAllSites ] = useState([]); 
  const [activeSites, setActiveSites] = useState([]); 
  const [dragState, setDragState] = useAtom(draggableAtom);

    // Fetch data from the API endpoint
    const { isFetching, error } = useQuery({
      queryKey: ['Site'],
      queryFn: async () => {
        const response = await axios.get('https://www.windnow.us/api/sites');
        setAllSites(response.data);
        setActiveSites(response.data.filter(site => site.Active === 1));
        return response.data;
      }
    });

    // Handle loading state
    if (isFetching) return 'Loading...'
  
    //Handle error state
    if (error) return 'An error has occurred: ' + error.message

    // Handle switch toggle
    const handleSwitchToggle = () => {
      setSwitchState(!switchState);
      if (!switchState) {  // If switch is currently off, filter active sites
        setActiveSites(allSites.filter(site => site.Active === 1));
      } else {  // If switch is currently on, set activeSites to all sites
        setActiveSites(allSites);
      }
    };

  // map data based on the selected criteria
  const getOptions = (criteria) => {
    if (!activeSites) return [];

    // Group active sites by the selected criteria
    const groupByCriteria = activeSites.reduce((acc, site) => {
      const key = site[criteria];
      acc[key] = acc[key] || [];
      acc[key].push(site);
      return acc;
    }, {});

    // Sorting groups
    const sortedGroups = Object.entries(groupByCriteria).sort(([keyA], [keyB]) => {
      if (criteria === 'SiteUID') {
        // Full string sort for SiteUID
        return keyA.localeCompare(keyB);
      } else {
        // Alphabetical sort for Customer and SiteID
        return keyA.localeCompare(keyB);
      }
    });

    // Create grouped options with sorted individual entries
    return sortedGroups.map(([key, sites]) => {
      const sortedSites = sites.sort((a, b) => {
        if (criteria === 'SiteUID') {
          // Sort by the full string for SiteUID within the group
          return a[criteria].localeCompare(b[criteria]);
        } else {
          return a[criteria].localeCompare(b[criteria]);
        }
      });

      // Check if group has multiple entries
      if (sites.length > 1) {
        // Multiple entries, create a group
        return {
          label: key,
          options: sortedSites.map(site => ({
            label: formatLabel(site, criteria),
            value: site.SiteUID,
            criteria: site[criteria],
            isInGroup: true
          }))
        };
      } else {
        // Single entry, no group needed
        const site = sortedSites[0];
        return {
          label: site[criteria].toString(),
          value: site.SiteUID,
          criteria: site[criteria],
          isInGroup: false
        };
      }
    }).flat();
  };

  // Custom labels depending on the selected criteria
  const formatLabel = (site, criteria) => {
    switch (criteria) {
      case 'Customer':
        return `${site.SiteID} - ${site.SiteUID}`;
      case 'SiteID':
        return `${site.Customer} - ${site.SiteUID}`;
      case 'SiteUID':
        return `${site.SiteID} - ${site.Customer}`;
      default:
        return site[criteria].toString();
    }
  };
    const selectSite = (selectedOption) => {
      if (selectedOption) {
        // Find the site object based on SiteUID
        const fullSiteObject = allSites.find(site => site.SiteUID === selectedOption.value);
    
        if (fullSiteObject) {
          setSite(fullSiteObject);
        } else {
          console.error('Selected site not found');
        }
      }
    };
    
    const handleCriteriaChange = (criteria) => {
      setSelectedCriteria(criteria);
    };
    
    const toggleDraggable = () => setDragState(!dragState);

    // Display fetched data as a dropdown
    return (
      <div className="" style={{ display: 'flex', alignItems: 'center' }}>
        <UncontrolledDropdown className="">
          <DropdownToggle caret >
            Select
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>
              Search by:
            </DropdownItem>
            <DropdownItem onClick={() => handleCriteriaChange('SiteID')}>SiteID</DropdownItem>
            <DropdownItem onClick={() => handleCriteriaChange('Customer')}>Customer</DropdownItem>
            <DropdownItem onClick={() => handleCriteriaChange('SiteUID')}>SiteUID</DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              <FormGroup switch inline>
                <Input
                  type="switch"
                  checked={switchState}
                  onChange={handleSwitchToggle} 
                />
                <Label>
                  Active Only
                </Label>
              </FormGroup>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              <FormGroup switch inline>
                {dragState ? (
                  <Label>Layout Unlocked</Label>
                ) : (
                  <Label>Layout Locked</Label>
                )}
                <Input
                  type="switch"
                  checked={dragState}
                  onChange={toggleDraggable} 
                />
              </FormGroup>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <Select
          options={getOptions(selectedCriteria)}
          onChange={selectSite}
          isClearable={true}
          isSearchable={true}
          placeholder={selectedCriteria}
          className="basic-single dropdown-size" 
          classNamePrefix="select"
          styles={customStyles}
        />
      </div>
    )
}