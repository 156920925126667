import { useFormContext } from 'react-hook-form';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';

export default function SubmitForm() {
  const { register, handleSubmit } = useFormContext();

  const onSubmit = data => {
    console.log(data); // To handle form submission, e.g., sending data to a server
  };

  return(
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label>Date System Installed:</Label>
        <Input
          name="dateInstalled"
          type="date"
          {...register("dateInstalled")}
        />
      </FormGroup>
      <FormGroup>
        <Button type="submit">Submit</Button>
      </FormGroup>
    </Form>
  );
}
