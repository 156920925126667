import { atom } from 'jotai';

export const tableAtom = atom([
    {
      SiteUID: 0,
      SiteID: '',
      Active: 0,
      SwarmID: 0,
      LastRx: '',
      TurbineStatus: 0,
      SiteNote: '',
      Customer: '',
      CustomerIndex: 0,
      MonVersion: '',
      WIFVersion: '',
      City: '',
      StateCode: '',
      Zip: '',
      CountryCode: '',
      Latitude: '',
      Longitude: ''
    }
  ]);