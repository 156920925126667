import { useFormContext } from 'react-hook-form';
import { Form, FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';

export default function TurbineForm() {
  const { register, formState: { errors } } = useFormContext();

  return(
    <Form>
      <FormGroup className="d-flex justify-content">
        <Input
          className="input-width"
          placeholder="Wind Turbine Model"
          {...register("windTurbineModel")}
        />
        <Input
          className="input-width"
          placeholder="Serial No. (e.g. 2018-00012)"
          id = 'SerialNo'
          {...register("serialNo", { required: "Serial No. is required" })}
        />
        {errors.serialNo && <span className="text-danger">{errors.serialNo.message}</span>}
        <UncontrolledTooltip
          placement = 'bottom'
          target = 'SerialNo'
        >Near top of yaw tube</UncontrolledTooltip>
        <Input 
          className="input-width"
          placeholder="Controller Serial No."
          {...register("controllerSerialNo", { required: "Controller Serial No. is required" })}
        />
        {errors.controllerSerialNo && <span className="text-danger">{errors.controllerSerialNo.message}</span>}
      </FormGroup>
      <FormGroup className="d-flex justify-content-between">
        <div>
          <Label>Controller:</Label>
          <Input 
            name="controllerType"
            type="radio"
            value="PowerSync III"
            {...register("controllerType")}
          />
          <Label check>PowerSync III</Label>
          <Input 
            name="controllerType"
            type="radio"
            value="Other"
            {...register("controllerType")}
          />
          <Label check>Other:</Label>
          <Input 
            className="input-width"
            {...register("controllerTypeOther")}
          />
        </div>
        <div>
          <Label>Blade Serial Numbers:</Label>
          <div className="d-flex justify-content">
            <Input 
              className="input-width"
              id="bladeSerialNo"
              {...register("bladeSerialNo1", { required: "Blade Serial No. 1 is required" })}
            />
            {errors.bladeSerialNo1 && <span className="text-danger">{errors.bladeSerialNo1.message}</span>}
            <Input 
              className="input-width"
              id="bladeSerialNo"
              {...register("bladeSerialNo2", { required: "Blade Serial No. 2 is required" })}
            />
            {errors.bladeSerialNo2 && <span className="text-danger">{errors.bladeSerialNo2.message}</span>}
            <Input 
              className="input-width"
              id="bladeSerialNo"
              {...register("bladeSerialNo3", { required: "Blade Serial No. 3 is required" })}
            />
            {errors.bladeSerialNo3 && <span className="text-danger">{errors.bladeSerialNo3.message}</span>}
            <UncontrolledTooltip
              placement = 'bottom'
              target = 'bladeSerialNo'
            >Stamped on blade root pad</UncontrolledTooltip>
          </div>
        </div>
      </FormGroup>
      <FormGroup className="d-flex justify-content-between">
        <Input 
          className="input-width"
          placeholder="Tower Type"
          {...register("towerType")}
        />
        <Input 
          className="input-width"
          placeholder="Height"
          {...register("height")}
        />
        <Input 
          className="input-width"
          placeholder="Anchor Type"
          {...register("anchorType")}
        />
      </FormGroup>
      <FormGroup className="d-flex justify-content-between">
        <div>
          <Label>Wire Type:</Label>
          <div>
            <Input 
              name="wireType"
              type="radio"
              value="Copper"
              {...register("wireType")}
            />
            <Label check>Copper</Label>
            <Input 
              name="wireType"
              type="radio"
              value="Aluminum"
              {...register("wireType")}
            />
            <Label check>Aluminum</Label>
          </div>
        </div>
        <Input 
          className="input-width"
          placeholder="Wire Run Length: Tower to Controller (ft)"
          {...register("wireRunLength")}
        />
        <Input 
          className="input-width"
          placeholder="Wire Size (gauge)"
          {...register("wireSize")}
        />
      </FormGroup>
    </Form>
  );
}

