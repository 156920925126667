const ConvertDate = ({ date }) => {
  const formatYYYYMM = (date) => {
    const [, month] = date.split('-');
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return monthNames[parseInt(month, 10) - 1];
  };

  const formatYYYYMMDD = (date) => {
    const [, month, day] = date.split('-');
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return `${monthNames[parseInt(month, 10) - 1]} ${parseInt(day, 10)}`;
  };

  const formatISO = (date) => {
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString('default', { month: 'long' });
    const day = dateObj.getDate();
    return `${month} ${day}`;
  };

  const formatDateTime = (date) => {
    const dateObj = new Date(date);
    const hours = dateObj.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    return `${formattedHours}:${minutes} ${ampm}`;
  };

  if (/^\d{4}-\d{2}$/.test(date)) {
    return formatYYYYMM(date);
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    return formatYYYYMMDD(date);
  } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(date)) {
    return formatISO(date);
  } else if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(date)) {
    return formatDateTime(date);
  } else {
    return date;
  }
};

export default ConvertDate;
