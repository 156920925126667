export default function ConvertTurbStatus(status) {
  const binaryStatus = status.toString(2).padStart(16, '0');
  let statusString = "";

  // Append status descriptions based on the binary status, corrected order
  if (Boolean(+binaryStatus[0])) statusString += "Disabled, "; //yellow
  if (Boolean(+binaryStatus[1])) statusString += "Wait, "; //yellow
  if (Boolean(+binaryStatus[2])) statusString += "Standby, "; //yellow
  if (Boolean(+binaryStatus[3])) statusString += "ConClosed, "; //red
  // if (Boolean(+binaryStatus[4])) statusString += "SelfTest, "; //green (probably come up with no wind)
  // if (Boolean(+binaryStatus[5])) statusString += "ITurbineZero, ";
  // if (Boolean(+binaryStatus[6])) statusString += "IDumpZero, ";
  // if (Boolean(+binaryStatus[7])) statusString += "NoZC, ";
  if (Boolean(+binaryStatus[8])) statusString += "Vcurve, "; //yellow
  if (Boolean(+binaryStatus[9])) statusString += "TargetReductionV, "; //yellow
  if (Boolean(+binaryStatus[10])) statusString += "TargetReductionRPM, "; //yellow
  if (Boolean(+binaryStatus[11])) statusString += "Spare0, ";
  if (Boolean(+binaryStatus[12])) statusString += "Alarm, "; //red
  if (Boolean(+binaryStatus[13])) statusString += "Startup, "; //green
  if (Boolean(+binaryStatus[14])) statusString += "NoWindTimeout, "; //yellow
  // if (Boolean(+binaryStatus[15])) statusString += "Spare1, ";

  // Remove trailing comma and space
  if (statusString.length > 0) {
    statusString = statusString.slice(0, -2);
  }

  return statusString;
}


