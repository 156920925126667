export default function ConvertShutdownStatus(SDS) {
  const binaryStatus = SDS.toString(2);
  let statusString = SDS.toString(16);

  // Iterating through each bit and appending corresponding status descriptions
  if (Boolean(+binaryStatus[15])) statusString += "HWFault, "; //all red
  if (Boolean(+binaryStatus[14])) statusString += "DCV_Over, ";
  if (Boolean(+binaryStatus[13])) statusString += "RPM_Over, ";
  if (Boolean(+binaryStatus[12])) statusString += "IDCripple, ";

  if (Boolean(+binaryStatus[11])) statusString += "PhaseLoss, ";
  if (Boolean(+binaryStatus[10])) statusString += "GroundFault, ";
  if (Boolean(+binaryStatus[9])) statusString += "HSTemp, ";
  if (Boolean(+binaryStatus[8])) statusString += "DumpTemp, ";

  if (Boolean(+binaryStatus[7])) statusString += "DumpCurrent, ";
  if (Boolean(+binaryStatus[6])) statusString += "DumpRes, ";
  if (Boolean(+binaryStatus[5])) statusString += "VRPM, ";
  if (Boolean(+binaryStatus[4])) statusString += "IntDCOV, ";

  if (Boolean(+binaryStatus[3])) statusString += "IntIdump, ";
  if (Boolean(+binaryStatus[2])) statusString += "InvMismatch, ";
  if (Boolean(+binaryStatus[1])) statusString += "Estop2, ";
  if (Boolean(+binaryStatus[0])) statusString += "InvFault, ";

  // Remove trailing comma and space
  if (statusString.length > 0) {
    statusString = statusString.slice(0, -2);
  }

  return statusString;
}
