import { useAtom } from 'jotai';
import { siteAtom } from '../../atoms/SiteAtom';
import { siteDataAtom } from '../../atoms/SiteDataAtom';

import {
  Card,
  CardBody,
} from 'reactstrap';

export default function SiteInfoCard() {
  const [site] = useAtom(siteAtom);
  const [siteData] = useAtom(siteDataAtom);

return(
  <>
  <Card className="site-container" style={{ color: "black" }}>
    <CardBody className="d-flex justify-content-around">
      <div>
        <p>SiteID</p>
        <h4>{ site.SiteID }</h4>
      </div>
      <div>
        <p>Customer</p>
        <h4>{ site.Customer }</h4>
      </div>
      <div>
        <p>UID</p>
        <h4>{ site.SiteUID }</h4>
      </div>
      <div>
        <p>LastRx</p>
        <h4>{ siteData.dbTimestamp }</h4>
      </div>
    </CardBody>
  </Card>
  </>
)
}