import { useFormContext } from 'react-hook-form';
import { Form, FormGroup, Input, Label } from 'reactstrap';

export default function UtilityForm() {
  const { register, formState: { errors } } = useFormContext();

  return (
    <Form>
      <FormGroup>
        <Input 
          placeholder="Name of Utility"
          {...register("nameOfUtility", { required: "Name of Utility is required" })}
        />
        {errors.nameOfUtility && <span>{errors.nameOfUtility.message}</span>}
        <div className="d-flex justify-content-between">
          <Label check>Net Metering?</Label>
          <div>
            <Input 
              name="netMetering"
              type="radio"
              value="Y"
              {...register("netMetering")}
            />
            <Label check>Y</Label>
            <Input 
              name="netMetering"
              type="radio"
              value="N"
              {...register("netMetering")}
            />
            <Label check>N</Label> 
          </div>
        </div>
      </FormGroup>
    </Form>
  );
}
