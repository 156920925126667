import { useFormContext } from 'react-hook-form';
import { Form, FormGroup, Input, Label } from 'reactstrap';

export default function BatteryBankForm() {
  const { register } = useFormContext();

  return(
    <Form>
      <FormGroup>
        <div className="d-flex justify-content">
          <Input
            className="input-width"
            placeholder="Battery Manufacturer & Model"
            {...register("batteryManufacturerModel")}
          />
          <Label>Voltage:</Label>
          <div>
            <Input 
              name="voltage"
              type="radio"
              value="24V"
              {...register("voltage")} 
            />
            <Label check>24V</Label>
          </div>
          <div>
            <Input 
              name="voltage"
              type="radio"
              value="48V"
              {...register("voltage")}
            />
            <Label check>48V</Label>
          </div>
          <div>
            <Input 
              name="voltage"
              type="radio"
              value="120V"
              {...register("voltage")} 
            />
            <Label check>120V</Label>
          </div>
          <div>
            <Input 
              name="voltage"
              type="radio"
              value="240V"
              {...register("voltage")} 
            />
            <Label check>240V</Label>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <Input
            className="input-width"
            placeholder="Battery Bank Amp Hours"
            {...register("batteryBankAmpHours")} 
          />
          <Input
            className="input-width"
            placeholder="No. of Battery Strings"
            {...register("numberOfBatteryStrings")} 
          />
          <Input
            className="input-width"
            placeholder="Inverter Manufacturer & Model"
            {...register("inverterManufacturerModel")}
          />
        </div>
      </FormGroup>
    </Form>
  );
}
