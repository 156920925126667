import { useQuery } from "@tanstack/react-query";
import axios from 'axios';
import { useAtom } from 'jotai';
import { siteAtom } from "../../atoms/SiteAtom";
import { eventsAtom } from "../../atoms/eventsAtom";
import ConvertTime from "../converters/TimestampConverter";
import convertEventCode from "../converters/EVcodeConverter";
import convertEventReason from "../converters/EVreasonConverter";
import EventsTable from "../tables-charts/EventsTable";


export default function EventsHandler() {
  // Initiate global states
  const [site,] = useAtom(siteAtom);
  const [ , setEvents] = useAtom(eventsAtom);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://www.windnow.us/api/events/${site.SiteUID}`);
      const fetchedData = response.data;
      console.log("Data fetched successfully:", fetchedData);
      try {
        // updateEventsAtom(fetchedData);
        updateEventsAtom(fetchedData);
      } catch (error) {
        console.error("Error", error);
      }  
      return fetchedData;  // Return data for caching by React Query
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;  // Re-throw the error to be caught by React Query
    }
  };
  // Use React Query to fetch data
  const { isLoading, isError, error } = useQuery({
    queryKey: ['Events', site.SiteUID],
    queryFn: fetchData,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  //Update the eventsAtom with data
function updateEventsAtom(fetchedData) {
  // Check if fetchedData is an array
  if (Array.isArray(fetchedData)) {
    const updatedEvents = fetchedData.map(event => ({
      ...event,
      TimeStamp: ConvertTime(event.TimeStamp),
      EVcodeNum: event.EVcode,
      EVcode: convertEventCode(event.EVcode),
      EVreason: convertEventReason(event.EVreason)
    }));

    // Update the atom with the transformed events or an empty array
    setEvents(updatedEvents);
    console.log("Updated eventsAtom with:", updatedEvents);
  } else {
    // Log or handle unexpected fetchedData formats
    console.error("Unexpected data format:", fetchedData);
  }
};


  // Render loading or Error
  const renderLoadingOrError = () => {
    if (isLoading) {
      return console.log('Loading...');
    } else if (isError) {
      return console.log(error);
    }
    return null;
  };

  // Display fetched data
  return (
    <>
      {renderLoadingOrError()}
      <EventsTable />
    </>
  );
}