import { SummaryGrid } from "../grids/SummaryGrid"
// import TurbHandler from "../components/handlers/Turb-handler"
// import TableHandler from "../components/handlers/Table-handler"

export default function AllTurbines() {
    return(
        <>
          <SummaryGrid />
        </>
    )
}