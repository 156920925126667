export default function ConvertHWStatus(status) {
  let statusString = "";
  const hwBATT_BIT = 12;
  const mask = 0x0FFF; // Mask for checking if the lower 12 bits are all zero

  // Check for special conditions
  if ((status & (1 << hwBATT_BIT)) !== 0) { 
      statusString += "BattMode: "; //yellow
  } else if ((status & mask) === 0) {
      statusString += " "; //"HW-OK "; //green
  }

  // Starting from bit 0 (least significant bit)
  for (let bit = 0; bit < 16; bit++) {
      if (status & (1 << bit)) {
          switch(bit) {
              case 0: statusString += "EStop, "; break; //red
              case 1: statusString += "PSFlt, "; break; //red
              case 2: statusString += "FanFlt, "; break; //red
              case 3: statusString += "DmpThSw, "; break; //red
              case 4: statusString += "ConFlt, "; break; //red
              case 5: statusString += "DumpTempFlt, "; break; //red
              case 6: statusString += "HSTempFlt, "; break; //red
              case 7: statusString += "SPCRC, "; break; //red
              case 8: statusString += "SPVersion, "; break; //red
              case 9: statusString += "PhLoss, "; break; //red
              case 10: statusString += "ConfigErr, "; break; //red
              case 11: statusString += "SPDFail, "; break; //red
              case 12: statusString += "HSTempWarn, "; break; //yellow
              case 13: statusString += "DumpTempWarn, "; break; //yellow
              case 14: statusString += " , "; break;
              case 15: statusString += "Spare4, "; break;
              default: // No default action needed
          }
      }
  }

  // Remove trailing comma and space
  if (statusString.endsWith(", ")) {
      statusString = statusString.slice(0, -2);
  }

  return statusString;
}
