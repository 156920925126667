import { useAtom } from 'jotai';
import { siteDataAtom } from "../../atoms/SiteDataAtom";
import ReactEcharts from 'echarts-for-react';

export default function PowerGauge(){
    const [siteData] = useAtom(siteDataAtom);
        let rpm = siteData.RPMMax;

    const option = {
        series: [
          {
            type: 'gauge',
            radius: '100%',
            center: ['50%', '50%'],
            startAngle: 200,
            endAngle: -200,
            min: 0,
            max: 60,
            splitNumber: 12,
            itemStyle: {
              color: '#d87c7c'
            },
            progress: {
              show: true,
              width: 20
            },
            pointer: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                show: false
              },
              axisLabel: {
                show: false
              },
            anchor: {
              show: false
            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              width: '60%',
              lineHeight: 40,
              borderRadius: 8,
              offsetCenter: [-135, 38],
              show: false
            },
            data: [
              {
                value: rpm
              }
            ]
          }
        ]
      };

      return(
        <ReactEcharts option = { option } style={{ height: '100%', width: '100%'}}/>
      )
    }