// Grid import
import { Responsive, WidthProvider } from "react-grid-layout";

// Form validation and submission
import { useForm, FormProvider } from 'react-hook-form';

// Component imports
import CustomerForm from "../components/forms/CustForm";
import DealerForm from "../components/forms/DealerForm";
import UltilityForm from "../components/forms/UtilityForm";
import HybridForm from "../components/forms/HybridForm";
import TurbineForm from "../components/forms/TurbineForm";
import BatteryBankForm from "../components/forms/BatteryBankForm";
import SubmitForm from "../components/forms/SubmitForm";

// Visuals imports
import {
    Card,
    CardBody,
    CardHeader,
    //Input
  } from 'reactstrap';

const ResponsiveGridLayout = WidthProvider(Responsive);

const Lglayout = [
  { i: "owner-name", x: 0, y: 0, w: 3, h: 3, minH: 3, minW: 3  },
  { i: "dealer-name", x: 3, y: 0, w: 3, h: 3, minH: 3, minW: 3  },
  { i: "local-utility-co", x: 4, y: 3, w: 2, h: 2, minH: 2, minW: 2  },
  { i: "hybrid-system", x: 4, y: 5, w: 2, h: 2.5, minH: 2.5, minW: 2  },
  { i: "turbine-info", x: 0, y: 3, w: 4, h: 3, minH: 3, minW: 4  },
  { i: "battery-bank", x: 0, y: 9, w: 4, h: 1.5, minH: 1.5, minW: 4  },
  { i: "upload", x: 0, y: 12, w: 4, h: 2, minH: 2, minW: 4  },
  { i: "submit", x: 4, y: 12, w: 2, h: 2, minH: 2, minW: 2  }

];

const Mdlayout = [
  { i: "owner-name", x: 0, y: 0, w: 3, h: 3, minH: 3, minW: 3  },
  { i: "dealer-name", x: 3, y: 0, w: 3, h: 3, minH: 3, minW: 3  },
  { i: "local-utility-co", x: 4, y: 3, w: 2, h: 2, minH: 2, minW: 2  },
  { i: "hybrid-system", x: 4, y: 5, w: 2, h: 2.5, minH: 2.5, minW: 2  },
  { i: "turbine-info", x: 0, y: 3, w: 4, h: 3, minH: 3, minW: 4  },
  { i: "battery-bank", x: 0, y: 9, w: 4, h: 1.5, minH: 1.5, minW: 4  },
  { i: "upload", x: 0, y: 12, w: 4, h: 2, minH: 2, minW: 4 },
  { i: "submit", x: 4, y: 12, w: 2, h: 2, minH: 2, minW: 2  }
];

const Smlayout = [
  { i: "owner-name", x: 0, y: 0, w: 2, h: 2, minH: 2, minW: 2  },
  { i: "dealer-name", x: 2, y: 0, w: 2, h: 2, minH: 2, minW: 2  },
  { i: "local-utility-co", x: 0, y: 2, w: 2, h: 2, minH: 2, minW: 2  },
  { i: "hybrid-system", x: 2, y: 2, w: 2, h: 2.5, minH: 2.5, minW: 2  },
  { i: "turbine-info", x: 0, y: 4, w: 4, h: 3, minH: 3, minW: 4  },
  { i: "battery-bank", x: 0, y: 6, w: 3, h: 1.5, minH: 1.5, minW: 3  },
  { i: "upload", x: 0, y: 9, w: 4, h: 2, minH: 2, minW: 4  },
  { i: "submit", x: 4, y: 9, w: 2, h: 2, minH: 2, minW: 2  }
];

const Xslayout = [
  { i: "owner-name", x: 0, y: 0, w: 2, h: 2, minH: 2, minW: 2  },
  { i: "dealer-name", x: 2, y: 0, w: 2, h: 2, minH: 2, minW: 2  },
  { i: "local-utility-co", x: 0, y: 2, w: 2, h: 2, minH: 2, minW: 2  },
  { i: "hybrid-system", x: 2, y: 2, w: 2, h: 2.5, minH: 2.5, minW: 2  },
  { i: "turbine-info", x: 0, y: 4, w: 4, h: 3, minH: 3, minW: 4  },
  { i: "battery-bank", x: 0, y: 6, w: 3, h: 1.5, minH: 1.5, minW: 3  },
  { i: "upload", x: 0, y: 9, w: 4, h: 2, minH: 2, minW: 4  },
  { i: "submit", x: 4, y: 9, w: 2, h: 2, minH: 2, minW: 2  }
];

const getLayouts = () => {
  const savedLayouts = localStorage.getItem("regform-grid-layout");

  return savedLayouts ? JSON.parse(savedLayouts) : { lg: Lglayout, md: Mdlayout, sm: Smlayout, xs: Xslayout };
};


export const RegFormGrid = (props) => {
  const methods = useForm();

  const handleLayoutChange = (layout, layouts) => {
    localStorage.setItem("regform-grid-layout", JSON.stringify(layouts));
  };

  const renderLoadingOrError = () => {
    if (props.isLoading) {
      return console.log('loading...')
    } else if (props.isError) {
      return console.log(props.errorMessage);
    }
    return null;
  };

  return (
    <>
    <FormProvider {...methods}>
        {renderLoadingOrError()}
        <ResponsiveGridLayout style={{zIndex:'0'}}
          layouts={getLayouts()}
          breakpoints={{ lg: 1920, md: 900, sm: 620, xs: 0 }}
          cols={{ lg: 6, md: 6, sm: 4, xs: 2 }}
          rowHeight={100}
          onLayoutChange={handleLayoutChange}
          draggableCancel=".cancelSelectorName"
          isDraggable={false}
          isResizable={false}
        >
          <div key="owner-name" style={{zIndex:'-1'}}>
            <Card className = "cancelSelectorName">
              <CardHeader>
                <h5>Owner Information</h5>
                </CardHeader>
              <CardBody>
                <CustomerForm/>
              </CardBody>
            </Card>
          </div>
          <div key="dealer-name" style={{zIndex:'-1'}}>
            <Card className = "cancelSelectorName">
              <CardHeader>
                <h5>Dealer Information</h5>
              </CardHeader>
              <CardBody>
                <DealerForm/>
              </CardBody>
            </Card>
          </div>
          <div key="local-utility-co" style={{zIndex:'-1'}}>
            <Card className = "cancelSelectorName">
              <CardHeader>
                <h5>Local Utility Company Information <br/> (if grid-connected system)</h5>
              </CardHeader>
              <CardBody>
                <UltilityForm/>
              </CardBody>
            </Card>
          </div>
          <div key="hybrid-system" style={{zIndex:'-1'}}>
            <Card className = "cancelSelectorName">
              <CardHeader>
                <h5>Hybrid System (if applicable)</h5>
              </CardHeader>
              <CardBody>
                <HybridForm/>
              </CardBody>
            </Card>
          </div>
          <div key="turbine-info" style={{zIndex:'-1'}}>
            <Card className = "cancelSelectorName">
              <CardHeader>
                <h5>Turbine Information</h5>
              </CardHeader>
              <CardBody>
                <TurbineForm/>
              </CardBody>
            </Card>
          </div>
          <div key="battery-bank" style={{zIndex:'-1'}}>
            <Card className = "cancelSelectorName">
              <CardHeader>
                <h5>Battery Bank Information (if applicable)</h5>
              </CardHeader>
              <CardBody>
                <BatteryBankForm/>
              </CardBody>
            </Card>
          </div>
          <div key="upload" style={{zIndex:'-1'}}>
            <Card className = "cancelSelectorName">
              <CardHeader>
                <h5>Upload</h5>
              </CardHeader>
              <CardBody>
              {/* <Input
                name="file"
                type="file"
              /> */}
              </CardBody>
            </Card>
          </div>
          <div key="submit" style={{zIndex:'-1'}}>
            <Card className = "cancelSelectorName">
              <CardHeader>
                <h5>Submit</h5>
              </CardHeader>
              <CardBody>
                <SubmitForm/>
              </CardBody>
            </Card>
          </div>
        </ResponsiveGridLayout>
      </FormProvider>
    </>
  );
};