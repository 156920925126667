import { useAtom } from 'jotai';
import { turbAtom } from "../../atoms/turbAtom";
import ReactEcharts from 'echarts-for-react';

export default function TurbChart(){
  const [turbData] = useAtom(turbAtom);

  if (!turbData || turbData.length === 0) {
    return null;
  }  

  console.log(turbData)
  // Extract Time and SumPinvAvg properties into separate arrays
  const site = turbData.map((item) => item.SiteID);
  const energy = turbData.map((item) => item.SumPturbAvg);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      calculable: true,
      legend: {
        data: ['Energy(kWh)'],
        itemGap: 5
      },
    },
    toolbox: {
      show: true,
      right: '10%',
      feature: {
        mark: { show: true },
        saveAsImage: { show: true }
      }
    },
    legend: {
      data: ['Energy(kWh)'],
      left: '10%'
    },
    xAxis: {
      type: 'value',
      name: "Energy(kWh)",
      axisLabel: {
        fontSize: 14
      }
    },
    series: [
      {
        data: energy,
        name: 'Energy(kWh)',
        type: 'bar',
        color: '#61a0a8',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)'
        }
      }
    ],
    yAxis: {
      type: 'category',
      name: 'Site',
      data: site,
      axisLabel: {
          fontSize: 12
      } 
    }
  }

  return (
    <ReactEcharts key={JSON.stringify(turbData.turbData)} className="cancelSelectorName" option={option} style={{ witdth: '100%'}} />
  )
}

