import { atom } from 'jotai';

export const siteDataAtom = atom([
  {
    SiteUID: 0,
    Pturb: 0,
    RPMMax: 0,
    InvStatus: '',
    dbTimestamp: ''
  }
]);