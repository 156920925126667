import { useAtom } from 'jotai';
import { eventsAtom } from '../../atoms/eventsAtom';
import { useState } from 'react';
import {
    Table as BTable, Card, CardBody
  } from 'reactstrap';
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel } from '@tanstack/react-table';

const columns = [
    {
      accessorKey: 'TimeStamp',
      header: 'TimeStamp',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'EVnumber',
      header: 'EVnumber',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'EVcode',
      header: 'EVcode',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'EVreason',
      header: 'EVreason',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'EVvalue',
      header: 'EVvalue',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'EVlimit',
      header: 'EVlimit',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'EVextra',
      header: 'EVextra',
      cell: info => info.getValue(),
    },
]

export default function EventsTable() {
  const [events, ] = useAtom(eventsAtom);
  const [sorting, setSorting] = useState([]);

  let data = events ? events: [];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Card style={{ overflow: 'auto', maxHeight: '100%' }}>
      <CardBody>
        <h4>Events</h4>
        <BTable className = "table-sticky cancelSelectorName" striped bordered hover responsive size = "sm">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key = {headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                  key = {header.id} 
                  colSpan={header.colSpan}           
                  onClick={header.column.getToggleSortingHandler()}
                  style={{
                    cursor: header.column.getCanSort() ? 'pointer' : undefined,
                    position: 'sticky'
                  }}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getIsSorted()
                      ? header.column.getIsSorted() === 'asc'
                        ? '▴'
                        : '▾'
                      : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </BTable>
      </CardBody>
    </Card>
  );
}
