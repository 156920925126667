// Grid import
import { Responsive, WidthProvider } from "react-grid-layout";

// Visuals imports
import {
    Container
  } from 'reactstrap';
import TurbHandler from '../components/handlers/Turb-handler';
import TableHandler from '../components/handlers/Table-handler';

//Global States
// import { useAtom } from "jotai";
// import { draggableAtom } from "../atoms/draggableAtom";

const ResponsiveGridLayout = WidthProvider(Responsive);

const Lglayout = [
  { i: "turb-summary", x: 0, y: 4, w: 6, h: 4, minH: 4, minW: 6  },
  { i: "table", x: 0, y: 0, w: 6, h: 4, minH: 4, minW: 6  }
];

const Mdlayout = [
  { i: "turb-summary", x: 0, y: 4, w: 6, h: 4, minH: 4, minW: 6  },
  { i: "table", x: 0, y: 0, w: 6, h: 4, minH: 4, minW: 6  }
];

const Smlayout = [
  { i: "turb-summary", x: 0, y: 4, w: 4, h: 4, minH: 4, minW: 4 },
  { i: "table", x: 0, y: 0, w: 4, h: 4, minH: 4, minW: 4  }
];

const Xslayout = [
  { i: "turb-summary", x: 0, y: 4, w: 2, h: 4, minH: 4, minW: 2  },
  { i: "table", x: 0, y: 0, w: 2, h: 4, minH: 4, minW: 2 }
];

const getLayouts = () => {
  const savedLayouts = localStorage.getItem("summary-grid-layout");

  return savedLayouts ? JSON.parse(savedLayouts) : { lg: Lglayout, md: Mdlayout, sm: Smlayout, xs: Xslayout };
};


export const SummaryGrid = (props) => {
  // const [ dragState, ] = useAtom(draggableAtom);

  const handleLayoutChange = (layout, layouts) => {
    localStorage.setItem("summary-grid-layout", JSON.stringify(layouts));
  };

  const renderLoadingOrError = () => {
    if (props.isLoading) {
      return console.log('loading...')
    } else if (props.isError) {
      return console.log(props.errorMessage);
    }
    return null;
  };

  return (
    <>
      <Container>
        {renderLoadingOrError()}
        <ResponsiveGridLayout style={{zIndex:'0'}}
          layouts={getLayouts()}
          breakpoints={{ lg: 1920, md: 900, sm: 620, xs: 0 }}
          cols={{ lg: 6, md: 6, sm: 4, xs: 2 }}
          rowHeight={100}
          onLayoutChange={handleLayoutChange}
          draggableCancel=".cancelSelectorName"
          isDraggable={false}
          isResizable={false}
        >
          <div key="turb-summary" style={{zIndex:'-1'}}>
            <TurbHandler className="cancelSelectorName" />
          </div>
          <div key="table" style={{zIndex:'-1'}}>
            <TableHandler className="cancelSelectorName"/>
          </div>
        </ResponsiveGridLayout>
      </Container>
    </>
  );
};