// Query-based imports
import { useQuery } from "@tanstack/react-query";
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from "react";

// State imports
import { useAtom, useAtomValue } from 'jotai';
import { turbAtom } from "../../atoms/turbAtom";
import { allTurbAtom } from "../../atoms/allTurbAtom";
import { siteFilterAtom } from "../../atoms/siteFilterAtom";

// Component imports
import {
  Card,
  CardBody,
  CardFooter
} from 'reactstrap';
import TurbMenu from "../tables-charts/TurbMenu";
import TurbChart from "../tables-charts/TurbChart";

export default function TurbHandler() {
  // Initiate global state
  const [ , setTurbData] = useAtom(turbAtom);
  const [ allTurbData, setAllTurbData ] = useAtom(allTurbAtom); 
  const siteFilter = useAtomValue(siteFilterAtom);
  const [selectedInterval, setSelectedInterval] = useState('6Weeks');

  // Fetch data from the API endpoint
  const fetchData = async () => {
    try {
      const response = await axios.get(`https://www.windnow.us/api/turb?interval=${selectedInterval}`);
      const fetchedData = response.data;
      console.log("Data fetched successfully:", fetchedData);
      try {
        setAllTurbData(fetchedData);
      } catch (error) {
        console.error("Error", error);
      }  
      return fetchedData;  // Return data for caching by React Query
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;  // Re-throw the error to be caught by React Query
    }
  };
  
 
  // Use React Query to fetch data
  const { isLoading, isError, error } = useQuery({
    queryKey: ['Turb', selectedInterval],
    queryFn: fetchData,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (siteFilter && siteFilter.length > 0) {
      // Filter the turbData based on the selected sites
      const filteredTurbData = allTurbData.filter(item => siteFilter.includes(item.SiteUID));
      setTurbData(filteredTurbData);
    } else {
      // If no sites are selected, use all data
      setTurbData(allTurbData);
    }
  }, [siteFilter, allTurbData, setTurbData]);

  const updateInterval = (newInterval) => {
    setSelectedInterval(newInterval);
  };

  // Render loading or error state
  const renderLoadingOrError = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    } else if (isError) {
      return <div>Error: {error.message}</div>;
    }
    return `Energy Production Over the Last ${selectedInterval}`;
  };

  return (
    <>
      <Card md={12} className="chart-card my-2 me-3 d-flex display-6 text-center justify-content-center">
        <CardBody>
          <TurbMenu onIntervalChange={updateInterval} />
          <TurbChart />
        </CardBody>
        <CardFooter>{renderLoadingOrError()}</CardFooter>
      </Card>
    </>
  );
}



