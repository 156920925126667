import { 
  ButtonToolbar,
  ButtonGroup,
  Button
} from 'reactstrap';

export default function TurbMenu({ onIntervalChange }) {

  const intervalOptions = ['6Weeks', '7Days', '24Hours'];

  return (
    <ButtonToolbar className="d-flex justify-content-center cancelSelectorName">
      <ButtonGroup className="me-2">
        {intervalOptions.map((item, index) => (
          <Button
            key={index}
            className="chart-button"
            onClick={() => onIntervalChange(item)}>
            {item}
          </Button>
        ))}
      </ButtonGroup>
    </ButtonToolbar>
  );
}
