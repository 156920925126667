// Global state imports
import { useAtom } from 'jotai';
import { siteDataAtom } from '../atoms/SiteDataAtom';
import { draggableAtom } from '../atoms/draggableAtom';
// Grid import
import { Responsive, WidthProvider } from "react-grid-layout";
// Visuals imports
import PowerGauge from '../components/tables-charts/PowerGauge';
import RPMGauge from '../components/tables-charts/RPMGauge';
import ChartHandler from '../components/handlers/Chart-handler';
import EventsHandler from '../components/handlers/Events-handler';
import {
    Card,
    Container,
    CardBody,
    CardFooter
  } from 'reactstrap';
import SiteData from '../components/handlers/SiteData-handler';
import SiteInfoCard from '../components/visuals/SiteInfoCard';

const ResponsiveGridLayout = WidthProvider(Responsive);
const LAYOUT_VERSION = '2.4';


const layoutLg = [
  { i: "site-info", x: 0, y: 0, w: 6, h: 1, minH: 1, minW: 6 },
  { i: "power", x: 0, y: 1, w: 2, h: 2, minH: 2, minW: 2 },
  { i: "rpm", x: 2, y: 1, w: 2, h: 2, minH: 2, minW: 2 },
  { i: "nuc", x: 4, y: 1, w: 2, h: 2, minH: 2, minW: 2 },
  { i: "status", x: 0, y: 3, w: 6, h: 1, minH: 1, minW: 6 },
  { i: "events", x: 0, y: 4, w: 6, h: 2, minH: 2, minW: 6 },
  { i: "chart", x: 0, y: 6, w: 6, h: 4, minH: 4, minW: 6  }
];

const layoutMd = [
  { i: "site-info", x: 0, y: 0, w: 6, h: 1, minH: 1, minW: 6 },
  { i: "power", x: 0, y: 1, w: 2, h: 2, minH: 2, minW: 2 },
  { i: "rpm", x: 2, y: 1, w: 2, h: 2, minH: 2, minW: 2 },
  { i: "nuc", x: 4, y: 1, w: 2, h: 2, minH: 2, minW: 2 },
  { i: "status", x: 0, y: 3, w: 6, h: 1, minH: 1, minW: 6 },
  { i: "events", x: 0, y: 4, w: 6, h: 2, minH: 2, minW: 6 },
  { i: "chart", x: 0, y: 6, w: 6, h: 4, minH: 4, minW: 6  }
];

const layoutSm = [
  { i: "site-info", x: 0, y: 0, w: 4, h: 1, minH: 1, minW: 4 },
  { i: "power", x: 0, y: 1, w: 2, h: 2, minH: 2, minW: 2 },
  { i: "rpm", x: 2, y: 1, w: 2, h: 2, minH: 2, minW: 2 },
  { i: "nuc", x: 4, y: 1, w: 2, h: 2, minH: 2, minW: 2 },
  { i: "events", x: 0, y: 3, w: 4, h: 2, minH: 2, minW: 4 },
  { i: "status", x: 0, y: 3, w: 4, h: 1, minH: 1, minW: 4 },
  { i: "events", x: 0, y: 4, w: 4, h: 2, minH: 2, minW: 4 },
  { i: "chart", x: 0, y: 6, w: 4, h: 2,  minH: 2, minW: 4  }
];

const layoutXs = [
  { i: "site-info", x: 0, y: 0, w: 2, h: 1, minH: 1, minW: 2 },
  { i: "power", x: 0, y: 1, w: 2, h: 2, minH: 2, minW: 2 },
  { i: "rpm", x: 2, y: 1, w: 2, h: 2, minH: 2, minW: 2 },
  { i: "nuc", x: 4, y: 1, w: 2, h: 2, minH: 2, minW: 2 },
  { i: "status", x: 0, y: 3, w: 2, h: 1, minH: 1, minW: 2 },
  { i: "events", x: 0, y: 4, w: 2, h: 2, minH: 2, minW: 2 },
  { i: "chart", x: 0, y: 6, w: 2, h: 2, minH: 2, minW: 2  }
];

const getLayouts = () => {
  const currentVersion = localStorage.getItem("layout-version");
  if (currentVersion !== LAYOUT_VERSION) {
    localStorage.setItem("layout-version", LAYOUT_VERSION);
    localStorage.removeItem("home-grid-layout"); // Remove old layout
    return { lg: layoutLg, md: layoutMd, sm: layoutSm, xs: layoutXs }; // Return default layouts
  }
  
  const savedLayouts = localStorage.getItem("home-grid-layout");
  return savedLayouts ? JSON.parse(savedLayouts) : { lg: layoutLg, md: layoutMd, sm: layoutSm, xs: layoutXs };
};




export const HomeGrid = (props) => {
  const [siteData] = useAtom(siteDataAtom);
  const [dragState,] = useAtom(draggableAtom);

  const handleLayoutChange = (layout, layouts) => {
    localStorage.setItem("home-grid-layout", JSON.stringify(layouts));
  };

  const renderLoadingOrError = () => {
    if (props.isLoading) {
      return console.log('loading...')
    } else if (props.isError) {
      return console.log(props.errorMessage);
    }
    return null;
  };

  return (
    <>
      <Container>
        {renderLoadingOrError()}
        <ResponsiveGridLayout style={{zIndex:'0'}}
          layouts={getLayouts()}
          breakpoints={{ lg: 1920, md: 900, sm: 620, xs: 0 }}
          cols={{ lg: 6, md: 6, sm: 4, xs: 2 }}
          rowHeight={100}
          onLayoutChange={handleLayoutChange}
          draggableCancel=".cancelSelectorName"
          isDraggable={dragState}
          isResizable={false}
        >
          <div key="site-info" style={{zIndex:'0'}}>
            <SiteInfoCard className="cancelSelectorName"/>
          </div>
          <div key="power" className = "overflow-visible" style={{zIndex:'-1'}}>
            <Card className = "d-flex display-6 text-center justify-content-center overflow-visible">
              <CardBody>
                <PowerGauge/>
              </CardBody>
              <CardFooter>{ siteData.Pturb } kW</CardFooter>
            </Card>
          </div>
          <div key="rpm" className = "overflow-visible" style={{zIndex:'-1'}}>
            <Card className = "d-flex display-6 text-center justify-content-center overflow-visible">
              <CardBody>
                <RPMGauge/>
              </CardBody>
              <CardFooter>{ siteData.RPMMax } RPM</CardFooter>
            </Card>
          </div>
          <div key="nuc" className = "overflow-visible" style={{zIndex:'-1'}}>
            <Card className = "d-flex display-6 text-center justify-content-center overflow-visible">
              <CardBody>
              </CardBody>
              <CardFooter>NUC</CardFooter>
            </Card>
          </div>
          <div key="events" className = "overflow-visible" style={{zIndex:'-1'}}>
            <EventsHandler />
          </div>
          <div key="status" className = "overflow-visible" style={{zIndex:'-1'}}>
            <SiteData />
          </div>
          <div key="chart" style={{ minHeight: '400px', zIndex:'-1' }}>
            <ChartHandler/>
          </div>
        </ResponsiveGridLayout>
      </Container>
    </>
  );
};