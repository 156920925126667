const EVStrings = {
  0: "Null Event",
  1: "Power Up", //green
  2: "Internal Vcc Low", //red
  3: "Internal Vcc High", //red
  4: "Internal V12P Low", //red
  5: "Internal V12P High", //red
  6: "Connection Fail", //red
  7: "Alarm", //red
  8: "Alarm Clear", //green
  9: "New Time", //yellow
  10: "New Version", //yellow
  11: "CRC Error", //red
  12: "Configuration Error", //red
  13: " ", //"Inverter Fault", //red
  14: "Inverter Grid Fault", //red
  15: " ", //"Status", //yellow
  16: "Event Clear", //yellow
  17: "Enable", //green
  18: "Disable", //green
  19: "Mode Change", //green
  20: "Communication Loss", //red
  21: "No Wind", //green
  22: "Standby Mode", //yellow
  23: " ", //"Over RPM", //red
  24: "Info Log",
  25: "Test Event",
  26: "CAN EX ID",
  27: "Start", //yellow
  28: "End", //yellow
  29: "CAN No Data", //red
  30: "CAN RTR", //red
  31: "CAN Buffer Length", //red
  32: "CAN Overflow", //red
  33: " ", //"Internal CAN Overflow", //red
  34: "Dump ThSw", //red
  35: "Internal VBus", //red
  36: "Internal ITurbine", //red
  37: "Internal IDump", //red
  38: "Internal Va", //red
  39: "Internal Vb", //red
  40: "EE Configuration Error", //red
  41: "Internal Vc",
};

const convertEventCode = (EVcode) => {
  return EVStrings[EVcode] || "Unknown Event";
};

export default convertEventCode;