import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { BrowserRouter as Router } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';

const queryClient = new QueryClient();

const onRedirectCallback = (appState) => {
  // Log the appState object to see its contents
  console.log('Redirect appState:', appState);

  // Logic to handle redirection after login
  window.location.href = appState?.returnTo || 'https://www.windnow.us/';
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
  domain='dev-fukenpkc45sc8qd7.us.auth0.com'
  clientId='Es1HEgzRRyP0nCp6bYrBD8QjGmD9XkEl'
  authorizationParams={{ 
    redirect_uri: 'https://www.windnow.us/'
  }}
  onRedirectCallback={onRedirectCallback}
>

      <QueryClientProvider client = { queryClient } contextSharing={true}>
        <Router>
          <App />
        </Router>
      </QueryClientProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

