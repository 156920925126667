import { useFormContext } from 'react-hook-form';
import { FormGroup, Input } from 'reactstrap';

export default function AddressForm() {
  const { register, formState: { errors } } = useFormContext();

  // Function to apply error styling class based on the presence of an error
  const inputClass = (fieldName) => errors[fieldName] === "required" ? 'input-error' : '';

  return (
    <FormGroup>
      <Input
        id="addressLine1"
        className={inputClass('addressLine1')}
        {...register("addressLine1", { required: true })}
        placeholder="Address Line 1*"
      />

      <Input
        id="addressLine2"
        className={inputClass('addressLine2')}
        {...register("addressLine2")}
        placeholder="Address Line 2"
      />

      <div className="d-flex justify-content-around">
        <Input
          id="city"
          className={inputClass('city')}
          {...register("city", { required: true })}
          placeholder="City*"
        />

        <Input
          id="state"
          className={inputClass('state')}
          {...register("state", {
            required: true,
            validate: value => value.length === 2 || "State must be 2 letters",
          })}
          maxLength="2"
          placeholder="State/Province*"
        />

        <Input
          id="zip"
          className={inputClass('zip')}
          {...register("zip", { required: true })}
          placeholder="Zip*"
        />

        <Input
          id="country"
          className={inputClass('country')}
          {...register("country", {
            required: true,
            validate: value => value.length === 2 || "Country must be 2 letters",
          })}
          maxLength="2"
          placeholder="Country*"
        />
      </div>
    </FormGroup>
  );
}




