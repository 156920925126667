export default function ConvertInvStatus(status) {
  const binaryStatus = status.toString(2).padStart(16, '0'); 
  let statusString = ""; 

  // Correct the bit order by checking from the right (LSB) to the left (MSB)
  if (Boolean(+binaryStatus[15])) statusString += " "; //"Ready, "; //green
  else statusString += "NotReady, "; //yellow

  if (Boolean(+binaryStatus[14])) {
    statusString += " "; //"GridOK, "; //green
  } else {
    statusString += " "; //"Grid: ";
    if (Boolean(+binaryStatus[13])) statusString += "ACVoltLow, "; //red
    if (Boolean(+binaryStatus[12])) statusString += "ACVoltHigh, "; //red
    if (Boolean(+binaryStatus[11])) statusString += "FreqLow, "; //red
    if (Boolean(+binaryStatus[10])) statusString += "FreqHigh, "; //red
  }

  if (Boolean(+binaryStatus[9])) statusString += " "; //"VbusOK, "; //green
  else statusString += "VbusLow, "; //yellow

  if (Boolean(+binaryStatus[8])) statusString += " "; //"Export, "; //green
  else statusString += "NoExport, "; //yellow

  if (Boolean(+binaryStatus[7])) statusString += "TempBackoff, "; //yellow
  if (Boolean(+binaryStatus[6])) statusString += "NonWIFMode, "; //red
  if (Boolean(+binaryStatus[5])) statusString += "InvSWVer, "; //red
  if (Boolean(+binaryStatus[4])) statusString += "InvCommLoss, "; //red

  if (Boolean(+binaryStatus[3])) statusString += "EndMkrErr, "; //red

  // Remove trailing comma and space
  if (statusString.endsWith(", ")) {
    statusString = statusString.slice(0, -2);
  }

  return statusString;
}
