import { useAtom } from 'jotai';
import { chartAtom } from "../../atoms/chartAtom";
import { useEffect } from 'react';
import { 
  ButtonToolbar,
  ButtonGroup,
  Button
} from 'reactstrap';

export default function ChartMenu() {
  const [chartState, setChartInterval] = useAtom(chartAtom);

  // Function to update global state with a selected site
  const selectInterval = (selectedInterval) => {
    setChartInterval((prevChartState) => ({
      ...prevChartState,
      Interval: selectedInterval, // Update the Interval property
    }));
  };

  // Use useEffect to log the updated state
  useEffect(() => {
    console.log(chartState);
  }, [chartState]); // Listen for changes in chartState

  const intervalOptions = ['12Months', '6Weeks', '7Days', '24Hours'];

  return (
    <ButtonToolbar className="d-flex justify-content-center">
      <ButtonGroup className="cancelSelectorName">
        {intervalOptions.map((item, index) => (
          <Button
          key={index}
          className={`chart-button ${
            item === chartState.Interval ? 'selected' : ''
          }`}
          onClick={() => selectInterval(item)}>
            {item}
          </Button>
        ))}
      </ButtonGroup>
    </ButtonToolbar>
  );
}
