// Query-based immports
import { useQuery } from "@tanstack/react-query";
import axios from 'axios';

// State imports
import { useAtom } from 'jotai';
import { tableAtom } from "../../atoms/tableAtom";
// import { allTurbAtom } from "../../atoms/allTurbAtom";
// import { turbAtom } from "../../atoms/turbAtom";
// import { allTurbAtom } from "../../atoms/allTurbAtom";
//import UpdateAllTurbData from "../UpdateAllTurbData";
// Component imports
import ConvertTurbStatus from "../converters/TurbStatusConverter";
import ConvertTime from "../converters/TimestampConverter";
import { allTableAtom } from "../../atoms/allTableAtom";
// import UpdateAllTurbData from "../UpdateAllTurbData";
import Table from "../tables-charts/Table";
import ConvertInvStatus from "../converters/InvStatusConverter";
import ConvertHWStatus from "../converters/HWStatusConverter";
import ConvertShutdownStatus from "../converters/SDStatusConverter";
// import { activeTableSitesAtom } from '../../atoms/activeTableSites';

export default function TableHandler() {
  // Initiate global state
  const [ , setTableData ] = useAtom(tableAtom);
  //const [ allTurbData, ] = useAtom(allTurbAtom);
  const [ , setAllTableData ] = useAtom(allTableAtom); 
  // const [ activeTableSites, setActiveTableSites ] = useAtom(activeTableSitesAtom);

  // Fetch data from the API endpoint
  const fetchData = async () => {
      try {
        const response = await axios.get(`https://www.windnow.us/api/turbTable`);
        const fetchedData = response.data;
        console.log("Data fetched successfully:", fetchedData);
        try {
          updateAllTableData(fetchedData);
        } catch (error) {
          console.error("Error", error);
        }  
        return fetchedData;  // Return data for caching by React Query
      } catch (error) {
        console.error("Error fetching data:", error);
        throw error;  // Re-throw the error to be caught by React Query
      }
    };
    
   
    // Use React Query to fetch data
    const { isLoading, isError, error } = useQuery({
      queryKey: ['Table'],
      queryFn: fetchData,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    });
    
    // Update Table Atom with data
    function updateAllTableData(fetchedData) {
      console.log("fetchedData:", fetchedData);
  
      const updatedData = fetchedData.map(item => ({
          ...item,
          LastRx: ConvertTime(item.LastRx),
          TurbineStatus: ConvertTurbStatus(item.TurbineStatus),
          HardwareStatus: ConvertHWStatus(item.HardwareStatus),
          ShutdownStatus: ConvertShutdownStatus(item.ShutdownStatus),
          InvStatus: ConvertInvStatus(item.InvStatus)
      }));
      console.log("updatedData:", updatedData);

      setAllTableData(updatedData);
      setTableData(updatedData);
    };

  // Render loading or Error
  const renderLoadingOrError = () => {
    if (isLoading) {
      return console.log('Loading...');
    } else if (isError) {
      return console.log(error);
    }
    return null;
  };

  return(
    <>
      {renderLoadingOrError()}
      <Table />
    </>
  )
};