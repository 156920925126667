import { useAtom } from 'jotai';
import { tableAtom } from "../../atoms/tableAtom";
import { useState } from 'react';
import {
    Table as BTable,
    Card,
    CardBody
  } from 'reactstrap';
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel } from '@tanstack/react-table';
import ConvertStatusColor from '../converters/StatusColorConverter';


export default function Table() {
  const [tableData] = useAtom(tableAtom);
  const [sorting, setSorting] = useState([]);

  let data = tableData ? tableData : [];

  const columns = [
    {
      accessorKey: 'SiteUID',
      header: 'UID',
      cell: info => info.getValue()
    },
    {
      accessorKey: 'SiteID',
      header: 'SiteID',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'Customer',
      header: 'Customer',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'SiteNote',
      header: 'SiteNote',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'LastRx',
      header: 'LastRx',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'TurbineStatus',
      header: 'TurbineStatus',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'InvStatus',
      header: 'InvStatus',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'HardwareStatus',
      header: 'HardwareStatus',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'ShutdownStatus',
      header: 'ShutdownStatus',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'WIFVersion',
      header: 'WIFVersion',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'City',
      header: 'City',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'StateCode',
      header: 'State',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'ZIP',
      header: 'ZIP',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'CountryCode',
      header: 'Country',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'Latitude',
      header: 'Latitude',
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'Longitude',
      header: 'Longitude',
      cell: info => info.getValue(),
    },
]

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Card md = {12} className = "p-2 cancelSelectorName">
      <CardBody className = "table-scroll table-sticky cancelSelectorName">
      <BTable className = "table-sticky cancelSelectorName" striped bordered hover responsive size = "sm">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key = {headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                key = {header.id} 
                colSpan={header.colSpan}           
                onClick={header.column.getToggleSortingHandler()}
                style={{
                  cursor: header.column.getCanSort() ? 'pointer' : undefined,
                  position: 'sticky'
                }}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                  {header.column.getIsSorted()
                    ? header.column.getIsSorted() === 'asc'
                      ? '▴'
                      : '▾'
                    : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} className={ConvertStatusColor(row)}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </BTable>
      </CardBody>
    </Card>
  );
}

