import { atom } from 'jotai';

export const chartAtom = atom([
    {
      SiteUID: 0,
      Time: {},
      SumPturbAvg: [],
      AvgRPMMax: [],
      Interval: "12Months",
    }
  ]);