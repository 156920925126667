const EVRStrings = {
  0: "Null",
  1: "Internal Fault",
  2: "FGround",
  3: "FG Disable",
  4: "High VBus",
  5: "Bad Mode",
  6: "Power Up",
  7: "RPM Control",
  8: "Dump Switch",
  9: "Turbine Control",
  10: "Manual",
  11: "OK",
  12: "Estop",
  13: "SPD",
  14: "Connection Fail",
  15: "Shutdown",
  16: "CAN",
  17: "Info",
  18: "Auto Restart",
  19: "Self Test"
};

const convertEventReason = (EVreason) => {
  console.log("Converting Event Reason:", EVreason);
  return EVRStrings[EVreason] || "Unknown Reason";
};

export default convertEventReason;