import { useAtom } from 'jotai';
import { chartAtom } from "../../atoms/chartAtom";
import ReactEcharts from 'echarts-for-react';
import ConvertDate from '../converters/DateConverter';

export default function Chart(){
  const [chartData] = useAtom(chartAtom);

  if (!chartData.chartData || chartData.chartData.length === 0) {
    return null;
  }  

  // Extract Time and SumPinvAvg properties into separate arrays
  const time = chartData.chartData.map((item) => item.Time);
  const energy = chartData.chartData.map((item) => item.SumPturbAvg);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      calculable: true,
      legend: {
        data: ['Energy(kWh)'],
        itemGap: 5
      },
      grid: {
        top: '12%',
        left: '1%',
        right: '10%',
        containLabel: true
      }
    },
    toolbox: {
      show: true,
      right: '10%',
      feature: {
        mark: { show: true },
        magicType: { show: true, type: ['line', 'bar'] },
        saveAsImage: { show: true }
      }
    },
    legend: {
      show: true,
      data:  ['Energy(kWh)'],
      left: '10%',
    },
    xAxis: {
      type: 'category',
      name: 'Time Span',
      data: time.map((date) => ConvertDate(date={date})),
      axisLabel: {
        fontSize: 14
      }
    },
    yAxis: {
      type: 'value',
      name: 'Energy(kWh)',
      axisLabel: {
          fontSize: 14
      } 
    },
    series: [
      {
        data: energy,
        name: 'Energy(kWh)',
        type: 'bar',
        color: '#61a0a8',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)'
        }
      }
    ]
  }

  return (
    <ReactEcharts option={option} className="cancelSelectorName" style={{height: '250px', witdth: '100%'}} />
  )
}
