import { RegFormGrid } from "../grids/RegFormGrid"

export default function RegForm() {

  return(
    <>
      <h2 className="d-flex justify-content-center display-6">Turbine Registration</h2>
      <RegFormGrid />
    </>
  )
}