import { useQuery } from "@tanstack/react-query";
import axios from 'axios';
import { useAtom } from 'jotai';
import { siteAtom } from "../../atoms/SiteAtom";
import { siteDataAtom } from "../../atoms/SiteDataAtom";
import ConvertTurbStatus from "../converters/TurbStatusConverter";
import ConvertTime from "../converters/TimestampConverter";
import {
  Card,
  CardBody,
} from 'reactstrap';
import ConvertHWStatus from "../converters/HWStatusConverter";
import ConvertInvStatus from "../converters/InvStatusConverter";
import ConvertShutdownStatus from "../converters/SDStatusConverter";

export default function SiteData() {
  // Initiate global states
  const [site] = useAtom(siteAtom);
  const [siteData, setSiteData] = useAtom(siteDataAtom);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://www.windnow.us/api/sitedata`);
      const fetchedData = response.data;
      console.log("Data fetched successfully:", fetchedData);
      try {
        updateSiteDataAtom(fetchedData);
      } catch (error) {
        console.error("Error", error);
      }  
      return fetchedData;  // Return data for caching by React Query
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;  // Re-throw the error to be caught by React Query
    }
  };
  // Use React Query to fetch data
  const { isLoading, isError, error } = useQuery({
    queryKey: ['SiteData', site.SiteUID],
    queryFn: fetchData,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  // Update the siteDataAtom with the data
  function updateSiteDataAtom(fetchedData){
    // Filter data based on selected SiteUID
    const filteredData = fetchedData.find((item) => item.SiteUID === site.SiteUID);

    // Convert status from 16-bit to status name
    if (filteredData) {
      const convertedTurbStatus = ConvertTurbStatus(filteredData.TurbineStatus);
      const convertedHWStatus = ConvertHWStatus(filteredData.HardwareStatus);
      const convertedInvStatus = ConvertInvStatus(filteredData.InvStatus);
      const convertedSDStatus = ConvertShutdownStatus(filteredData.ShutdownStatus);
      const convertedTime = ConvertTime(filteredData.dbTimestamp);

      // Add the converted status and time to the filtered data
      const updatedData = { 
        ...filteredData, 
        TurbineStatus: convertedTurbStatus, 
        HardwareStatus: convertedHWStatus, 
        ShutdownStatus: convertedSDStatus,
        InvStatus: convertedInvStatus,
        dbTimestamp: convertedTime };

      setSiteData(updatedData);
      console.log("Updated SiteDataAtom with:", updatedData);
    }
  };

  // Render loading or Error
  const renderLoadingOrError = () => {
    if (isLoading) {
      return console.log('Loading...');
    } else if (isError) {
      return console.log(error);
    }
    return null;
  };

  // Display fetched data as cards
  return (
    <>
    <Card className="site-container" style={{ color: "black" }}>
      <CardBody className="d-flex justify-content-around">
        {renderLoadingOrError()}
        <div>
          <p>Turbine Status</p>
          <h4>{ siteData.TurbineStatus }</h4>
        </div>
        <div>
          <p>Inverter Status</p>
          <h4>{ siteData.InvStatus }</h4>
        </div>
        <div>
          <p>HardwareStatus</p>
          <h4>{ siteData.HardwareStatus }</h4>
        </div>
        <div>
          <p>Shutdown Status</p>
          <h4>{ siteData.ShutdownStatus }</h4>
        </div>
      </CardBody>
    </Card>
    </>
  );
}